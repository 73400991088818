<div class="device-management__container">
  <h2>{{'settings_device_management.titles' | translate | translateCut: 0}}</h2>
  <p>{{'settings_device_management.titles' | translate | translateCut: 1}}</p>
  <div class="device-management__devices-list" *ngIf="loggedInDevicesList.length > 0">
    <div *ngFor="let device of loggedInDevicesList; let index = index"
         [class.device-management__device-item-mobile]="device.deviceType == deviceTypes.MOBILE || device.deviceType == deviceTypes.TABLET"
         [class.device-management__device-item-desktop]="!(device.deviceType == deviceTypes.MOBILE || device.deviceType == deviceTypes.TABLET)"
         [class.current-device]="false">
      <div class="device-management__device-item-name">
        <svg>
          <use href="assets/images/sprite.svg#mobile"
               *ngIf="device.deviceType == deviceTypes.MOBILE || device.deviceType == deviceTypes.TABLET"/>
          <use href="assets/images/sprite.svg#desktop"
               *ngIf="!(device.deviceType == deviceTypes.MOBILE || device.deviceType == deviceTypes.TABLET)"/>
        </svg>
        <div>
          <p>{{device.deviceBrand}}</p>
          <p class="current-device-text"
             *ngIf="false">{{'settings_device_management.logout_btn' | translate | translateCut: 1}}</p>
        </div>
      </div>

      <button *ngIf="!device.isCurrentDevice" class="btn btn-link"
              (click)="removeDevice(device, index)">{{'settings_device_management.logout_btn' | translate | translateCut: 0}}</button>
    </div>
  </div>
  <!--  <button class="btn btn-danger device-management__continue-btn">Continue</button>-->
</div>
