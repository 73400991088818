import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginAuthGuard} from "@guards/login-auth.guard";
import {addLanguageGuard, generateLanguageRoutes, getWildcardRoutes} from "@services/routing/routing-helper/routing-helper";
import {AVAILABLE_LANGUAGES} from "@constants/ui.constants";
import {DEFAULT_LOGIN_PATH, NOT_FOUND_PATH} from "@constants/http.constants";
import {CustomRoutes} from "@services/routing/routing-helper/routing.interface";

const routes: CustomRoutes = [
  {
    path: DEFAULT_LOGIN_PATH,
    loadComponent: () => import('@login/modules/join/join.component')
      .then(mod => mod.JoinComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'signup',
    loadComponent: () => import('@login/modules/signup/signup.component')
      .then(mod => mod.SignupComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'otp-verification',
    loadComponent: () => import('@login/modules/otp-verification/otp-verification.component')
      .then(mod => mod.OtpVerificationComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'activate-account',
    loadComponent: () => import('@login/modules/activate-account/activate-account.component')
      .then(mod => mod.ActivateAccountComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('@login/modules/login/login.component')
      .then(mod => mod.LoginComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'forget-password',
    loadComponent: () => import('@login-modules/forget-password/forget-password.component')
      .then(mod => mod.ForgetPasswordComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'reset-password',
    loadComponent: () => import('@login-modules/reset-password/reset-password.component')
      .then(mod => mod.ResetPasswordComponent),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'redirect-center',
    loadChildren: () => import('@login-modules/redirect-center/redirect-center.module')
      .then(mod => mod.RedirectCenterModule)
  },
  {
    path: 'sandbox-auth',
    loadChildren: () => import('@login-modules/sandbox-auth/sandbox-auth.module')
      .then(mod => mod.SandboxAuthModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: NOT_FOUND_PATH,
    loadChildren: () => import('@login-modules/not-found/not-found.module')
      .then(mod => mod.NotFoundModule),
    canActivate: [LoginAuthGuard]
  },
];

addLanguageGuard(routes);
const languageRoutes: Routes = generateLanguageRoutes(AVAILABLE_LANGUAGES, routes);
const wildcardRoutes: Routes = getWildcardRoutes(DEFAULT_LOGIN_PATH, AVAILABLE_LANGUAGES);

@NgModule({
  imports: [
    RouterModule.forRoot([
      ...routes,
      ...languageRoutes,
      ...wildcardRoutes,
    ], {
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
