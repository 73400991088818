import {Component, OnInit} from '@angular/core';
import {DialogService} from "@services/custom-dialogs/dialog.service";
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {DeviceManagementModule} from '@components/device-management/device-management.module';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@pipes/alm-translate-cut';

@Component({
  selector: 'alm-root-device-management-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    DeviceManagementModule,
    TranslateModule,
    AlmTranslateCutModule
  ],
  templateUrl: './device-management-dialog.component.html',
  styleUrls: ['./device-management-dialog.component.scss']
})
export class DeviceManagementDialogComponent implements OnInit {

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogService.closeDialog('device-manage');
  }

}
