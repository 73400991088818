import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateConfig} from '@services/language/ngx-translate.config';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorSncakBarModule} from '@components/error-sncak-bar/error-sncak-bar.module';
import {InfoSnackBarModule} from '@components/info-snack-bar/info-snack-bar.module';
import {ToastrModule} from 'ngx-toastr';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {environment} from '@environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AngularFireModule} from '@angular/fire/compat';
import {FooterModule} from '@components/footer/footer.module';
import {UiLoaderModule} from '@components/ui-loader/ui-loader.module';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {SimplifiedHeaderModule} from '@components/simplified-header/simplified-header.module';
import {SimplifiedFooterModule} from '@components/simplified-footer/simplified-footer.module';
import {AuthDialogContainerModule} from '@components/auth-dialog-container/auth-dialog-container.module';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {CookieModule} from 'ngx-cookie';
import * as Sentry from '@sentry/angular-ivy';
import {Router} from '@angular/router';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {ApiGatewayInterceptor} from '@interceptors/api-gateway.interceptor';
import {REQUESTER_APP_HEADER_VALUE, USER_TYPE} from '@constants/http.constants';
import {LanguageInterceptor} from '@interceptors/language.interceptor';
import {UserTypes} from '@interfaces/authorized-user/user.interface';
import {AddEmailDialogComponent} from '@components/add-email-dialog/add-email-dialog.component';
import {DeviceManagementDialogComponent} from '@components/device-management-dialog/device-management-dialog.component';
import {VerificationInputDialogComponent} from '@components/verification-input-dialog/verification-input-dialog.component';

function beforeAppInitFactory(connector: ConnectorV2Service) {
  return () => {
    connector.initConnector();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot(TranslateConfig),
    AlmTranslateCutModule.forRoot(),
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      enableHtml: true,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: true,
      maxOpened: 4,
    }),
    CookieModule.withOptions(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatSnackBarModule,
    ErrorSncakBarModule,
    InfoSnackBarModule,
    HttpClientModule,
    DeviceManagementDialogComponent,
    VerificationInputDialogComponent,
    AddEmailDialogComponent,
    FooterModule,
    UiLoaderModule,
    SimplifiedHeaderModule,
    SimplifiedFooterModule,
    AuthDialogContainerModule,
  ],
  providers: [
    CustomHttpService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: beforeAppInitFactory,
      deps: [ConnectorV2Service, Sentry.TraceService],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: ApiGatewayInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    { provide: REQUESTER_APP_HEADER_VALUE, useValue: 'LOGIN_WEB' },
    { provide: USER_TYPE, useValue: UserTypes.Anonymous },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
