import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {LanguageControlService} from '@services/language/language-control.service';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {isPlatformBrowser} from '@angular/common';
import {skip, skipWhile} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {ZendeskService} from '@services/zendesk/zendesk.service';
import {SharedSessionStorageService} from '@services/shared-session-storage/shared-session-storage.service';
import {REDIRECT} from '@constants/session-storage.constants';
import commonAr from '@shared-translations/http-errors/common-ar.json';
import commonEn from '@shared-translations/http-errors/common-en.json';
import authAr from '@shared-translations/http-errors/auth-ar.json';
import authEn from '@shared-translations/http-errors/auth-en.json';

@Component({
  selector: 'alm-login-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
              private router: Router,
              private languageControl: LanguageControlService,
              private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: any,
              private gtmService: GoogleTagManagerService, private zendeskService: ZendeskService,
              private sharedSessionStorageService: SharedSessionStorageService,
              ) {
    this.languageControl.setTranslations('ar', {...commonAr,...authAr});
    this.languageControl.setTranslations('en', {...commonEn,...authEn});
  }

  ngAfterViewInit(): void {
    this.scriptsLoader();
  }

  ngOnInit(): void {
    this.sharedSessionStorageService.syncItem(REDIRECT);
    this.connectorService.connectorLoaded.pipe(skip(1)).toPromise().then((loaded) => {
      this.connectorService.connectorUser.pipe(
        skip(1),
        skipWhile(u =>  !this.connectorService.isCheckingAuthorization)
      ).subscribe((user) => {
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            if (user) {
              this.router.navigate(['/redirect-center'], {queryParams: {'prevError': '001'}});
            } else {
              this.router.navigate(['/login']);
            }
          }, 1500);
        }
      });
    });
  }

  private scriptsLoader() {
    this.gtmService.loadGtm(environment.gtmID);
    this.zendeskService.loadZendeskWidget('ca70126b-03f9-45fb-8bb3-daead16e4adf');
  }
}
