import {Component, Input, OnInit} from '@angular/core';
import {EDeviceType, IDevice} from '@interfaces/common/device.interface';
import {UserService} from '@services/integrations/user/user.service';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import {SnackBarControlService} from '@services/snack-bar/snack-bar-control.service';
import {IHttpError} from '@interfaces/common/http.interface';
import en from '@components/device-management/i18n/en.json';
import ar from '@components/device-management/i18n/ar.json';
import {LanguageControlService} from '@services/language/language-control.service';
import {VerificationInputDialogComponent} from '@components/verification-input-dialog/verification-input-dialog.component';
import {AuthV2Service} from '@services/integrations/auth-v2/auth-v2.service';
import {Router} from '@angular/router';
import {UserManagerService} from "@services/user/user-manager.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'alm-root-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss']
})
export class DeviceManagementComponent implements OnInit {

  @Input() isUserSettings: boolean = false;
  loggedInDevicesList: IDevice[] = [];
  deviceTypes = EDeviceType;

  constructor(private userManagerService: UserManagerService, private uiLoader: UiLoaderService,
              private dialogService: DialogService, private authV2Service: AuthV2Service,
              private snackbarControl: SnackBarControlService, private languageService: LanguageControlService,
              private router: Router) {
    this.languageService.setTranslations('en', en);
    this.languageService.setTranslations('ar', ar);
  }

  ngOnInit(): void {
    this.getDevices();
  }

  getDevices(): void {
    this.uiLoader.startUiLoader('device-management-loader');
    this.authV2Service.getUserDevices({}).then(devices => {
      this.loggedInDevicesList = devices;
      this.uiLoader.stopUiLoader('device-management-loader');
    }).catch((e: IHttpError) => {
      this.snackbarControl.openHttpErrorSnackbar(e.code);
      this.uiLoader.stopUiLoader('device-management-loader');
    });
  }

  removeDevice(device: IDevice, index: number): void {
    let userInfo = this.userManagerService.userInfo.getValue();
    this.uiLoader.startUiLoader('send-pin');
    this.authV2Service.sendPinCode('device-management', false, userInfo?.email).then(result => {
      if (result.result) {
        lastValueFrom(this.dialogService.openDialog(VerificationInputDialogComponent,
          'verification-input',
          {
            removeDevice: true,
            device: device
          }).afterClosed()).then(result => {
          if (result) {
            if (this.isUserSettings) {
              this.getDevices();
            } else {
              this.router.navigate(['/home']);
            }
          }
        });
      } else {
        this.snackbarControl.openErrorSnackbar(`http_error.0`);
      }
      this.uiLoader.stopUiLoader('send-pin');
    }).catch((e) => {
      this.snackbarControl.openHttpErrorSnackbar(e.code);
      this.uiLoader.stopUiLoader('send-pin');
    });
  }

}
